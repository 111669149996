import rewardPool from "./rewardPool.json";
import bpt from "./bpt.json";
import syx from "./syx.json";
import bptRefConnector from "./bptRefConnector.json";
import wvlxConnector from "./wvlxConnector.json";
import connectorFactory from "./connectorFactory.json";
import erc20 from "./erc20.json";
import exchangeProxy from "./exchangeProxy.json";
import pancakePair from "./pancakePair.json";
import rewardEscrow from "./rewardEscrow.json";

const rewardPoolABI = rewardPool;
const bptABI = bpt;
const syxABI = syx;
const bptRefConnectorABI = bptRefConnector;
const wvlxConnectorABI = wvlxConnector;
const connectorFactoryABI = connectorFactory;
const erc20ABI = erc20;
const exchangeProxyAbi = exchangeProxy;
const pancakePairAbi = pancakePair;
const rewardEscrowAbi = rewardEscrow;

export default {
  rewardPoolABI,
  bptABI,
  syxABI,
  bptRefConnectorABI,
  wvlxConnectorABI,
  connectorFactoryABI,
  erc20ABI,
  exchangeProxyAbi,
  pancakePairAbi,
  rewardEscrowAbi,
};
